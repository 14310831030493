<template>
  <div class="business-page">
    <div class="business-banner-wrap">
      <!-- <img src="@/image/business/banner.png" /> -->
      <div class="nav-bar-wrap">
        <div class="nav-item" 
          :key="index"
          :id="'item'+item.categoryId"
          @click="handleCategoryClick(index)" 
          :class="{active:selectedCategoryId==item.id}"
          v-for="(item,index) in categoryList">{{item.name}}</div>
      </div>
    </div>
    <!--导航条-->
    <navigation-bar pageName="业务领域"></navigation-bar>

    <div class="category-item-wrap" :key="index" v-for="(item,index) in detailList">
      <section-title :chTitle="item.cmsTitle"></section-title>
      <div class="category-item-content">
        <img :src="item.titleImgUrl"  v-if="item.titleImgUrl" class="category-item-intro-icon" />
        <div v-html="item.description" class="category-item-intro business-content">
        </div>
        <!-- <div class="lean-more-link" @click="handleMoreClick(item)">了解更多...</div> -->
      </div>
    </div>
    
    <div v-if="detailList.length===0" style="height: 200px;display: flex;align-items: center;justify-content: center;font-size: 12px;">暂未公布</div>

  </div>
</template>

<script>
  import NavigationBar from '@/components/navigation-bar.vue'
  import SectionTitle from '@/components/section-title.vue'
  import {
    Swiper,
    SwiperSlide
  } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'

  import {
    getBusinessCategory,
    getCategoryDetail
  } from '@/api/business.js'

 
  export default {
    components: {
      NavigationBar,
      SectionTitle,
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        selectedCategoryId:null,
        categoryId: null,
        categoryList: [],
        detailList: []
      }
    },
    created() {
      this.selectedCategoryId = this.$route.query.categoryId
      this.getBusinessCategory()
    },
    computed: {

    },
    methods: {
      getBusinessCategory() {
        getBusinessCategory().then(data => {
          this.categoryList = data.data
          if(!this.selectedCategoryId){
            this.selectedCategoryId = this.categoryList[0].id
          }
          this.getCategoryDetail(this.selectedCategoryId)
          
          this.$nextTick(()=>{
            document.getElementById('item'+this.selectedCategoryId).scrollIntoView(true)
          })
          
        })
      },
      getCategoryDetail(categoryId) {
        this.selectedCategoryId = categoryId
        getCategoryDetail(categoryId).then(data => {
          this.detailList = data.page.list
        })
      },
      handleCategoryClick(index) {
        // this.activeIndex = index
        const category = this.categoryList[index]
        // debugger
        this.getCategoryDetail(category.id)
        // this.$router.push({path:'/business',query:{categoryId:category.categoryId}})
      },
      handleMoreClick(item){
        this.$router.push({path:'/business-detail',query:{detailId:item.id}})
      }
    }
  }
</script>

<style lang="scss">
  .business-page {
    padding-bottom: 30px;
    .business-banner-wrap{
      position: relative;
      padding-top: 79%;
      background-image: url(../../image/business/banner.png);
      background-repeat: no-repeat;
      background-size: 100%;
    }
    
    

    .section-title{
      height: 70px;
    }
    .ch-title {
      font-size: 22px;
      line-height: 30px;
    }

    .category-item-wrap {
      padding: 20px 0px;
    }

    .category-item-content {
      padding: 0px 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // text-align: center;

      .category-item-intro-icon {
        // width: 95px;
        max-width: 90%;
      }

      .category-item-intro {
        margin-top: 15px;
        font-size: 13px;
        line-height: 18px;
        color: #525252;
      }

      .lean-more-link {
        margin-top: 5px;
        line-height: 40px;
        font-size: 12px;
        color: #f36314;
      }

    }

  }
  
  .business-content .summary{
    font-size: 12px;
  }
  
  .business-content .intro-category-title{
    padding-top: 10px;
    font-weight: bold;
    font-size: 12px;
    color: #f36314;
  }
  .business-content .intro-category-content{
    font-size: 12px;
    position: relative;
  
  }
  .business-content .intro-category-content.ys{
    padding-left: 15px;
  }
  .business-content .intro-category-content.ys::before{
    position: absolute;
    content: " ";
    left: 0px;
    top: 6px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #000;
  }
</style>
